import React from "react";
import "./ProfileComponent.css";

export default class ProfilePriyaComponent extends React.Component {
  componentDidMount() {
    const logoTitle = document.querySelector("#logo-title");
    logoTitle.innerHTML = "priya nath";
  }

  componentWillUnmount() {
    const logoTitle = document.querySelector("#logo-title");
    logoTitle.innerHTML = "sabarish narain";
  }

  render() {
    return (
      <div id="contents">
        <div style={{ display: "flex" }}>
          <div style={{ width: "40%", padding: "20px" }}>
            <img src="Priya_Profile.jpg" width="80%" />
          </div>

          <div style={{ width: "60%", padding: "20px" }}>
            <div>
              <div style={{ alignItems: "center", marginBottom: "20px" }}>
                <h3> About Priya</h3>
                <a
                  href="https://www.linkedin.com/in/priya-nath-b7693678"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="linkedin-logo.png"
                    width="60px"
                    height="15px"
                    style={{ marginLeft: "10px" }}
                  />
                </a>
              </div>

              <p>
                I am an experienced lead developer in reporting & analytics
                building business intelligence solutions and strategies for
                clients. We are surrounded by data and I enjoy working with
                it.Tailoring data according to business need with impactful
                insight always excites me. I have a strong understanding in
                client relationship, leadership, business analytics, Data ware
                housing, Big Data concept, database structures, risk management
                principles. Technologies change rapidly so learning is the key
                mantra. So I try to learn the tools/technologies which not only
                help me at my job but also improve my professional goal. I am a
                true believer of team success, so I always look for chances to
                share my knowledge to the team. Outside my professional
                interests, I am a wife, dancer, ping pong player, gardner and
                fantastic cook.
              </p>
            </div>

            <div style={{ width: "100%" }}>
              <div>
                <h3> My Experience </h3>
              </div>

              <div>
                <table className="profile-table">
                  <tbody>
                    <tr>
                      <td>Analytics & Reporting:</td>
                      <td>
                        Looker, Arcadia, Paxata, Cognos 10 & 11, Power BI,
                        Pentaho, Advanced Excel, VBA & Macro
                      </td>
                    </tr>

                    <tr>
                      <td>Query/ETL Tool:</td>

                      <td>
                        Hive-HUE, SQL developer, SQL Workbench, Toad, Pentaho
                        Data Integration
                      </td>
                    </tr>

                    <tr>
                      <td>Database/ Datalake:</td>

                      <td>Hive, Oracle 12 C, Green Plum</td>
                    </tr>

                    <tr>
                      <td>Query Language:</td>

                      <td> Oracle SQL, Hive-sql, DAX, Power Query </td>
                    </tr>

                    <tr>
                      <td>Issue tracking:</td>

                      <td> JIRA, Kanban Flow </td>
                    </tr>

                    <tr>
                      <td>Other Tools:</td>

                      <td>
                        {" "}
                        Tectia, WinSCP, Putty, Motio PI, SVN, Power Point, Visio
                      </td>
                    </tr>

                    <tr>
                      <td>SDLC Methodologies:</td>

                      <td> Agile, Waterfall </td>
                    </tr>

                    <tr>
                      <td>Programming Language:</td>

                      <td> HTML/CSS, C, Core java </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
