import React from "react";
import { Link } from "react-router-dom";
import { LogoComponentXXL } from "../common/LogoComponent";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default class OurTeamComponent extends React.Component {
  renderIcon(text, color, fontColor) {
    return (
      <Paper
        sx={{ m: 1, padding: "10px", backgroundColor: color }}
        elevation={4}
      >
        <Typography
          sx={{ color: fontColor, fontWeight: "bold", fontSize: "xx-large" }}
        >
          {" "}
          {text}
        </Typography>
      </Paper>
    );
  }

  render() {
    return (
      <div id="contents">
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div>
              <Link to="/me"> Sabarish Subramanian</Link> &
              <Link to="/priya"> Priya Nath </Link>
            </div>
            <div style={{ marginTop: "2em" }}>
              <img src="sabarish-priya-team-pic.jpg" style={{ width: "60%" }} />
            </div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ height: 120 }}>
              <Box sx={{ display: "flex" }}>
                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...{ transitionDelay: 5000, timeout: 5000 }}
                >
                  {this.renderIcon("Learn, ", "lightseagreen", "white")}
                </Grow>

                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...{ transitionDelay: 5000, timeout: 5000 }}
                >
                  {this.renderIcon(
                    "Build, ",
                    "lightgoldenrodyellow",
                    "dodgerblue",
                  )}
                </Grow>
                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...{ timeout: 8000 }}
                >
                  {this.renderIcon("Iterate..", "lightsalmon", "white")}
                </Grow>
              </Box>
            </Box>

            <LogoComponentXXL />
          </div>
        </div>
      </div>
    );
  }
}
