import React from "react";
import { Link } from "react-router-dom";
import { LogoComponent } from "./common/LogoComponent";
import "./HeaderComponent.css";

export default class WelcomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: "welcome",
    };
    this.handleNavigate = this.handleNavigate.bind(this);
  }

  handleNavigate(value) {
    this.setState({ navigate: value });
  }

  render() {
    return (
      <div id="header">
        <div>
          <LogoComponent />
          <ul id="navigation">
            <li
              className={this.state.navigate === "welcome" ? "active" : ""}
              onClick={() => this.handleNavigate("welcome")}
            >
              <Link to="/">Welcome</Link>
            </li>

            <li
              className={this.state.navigate === "aboutus" ? "active" : ""}
              onClick={() => this.handleNavigate("aboutus")}
            >
              <Link to="/aboutus">About Us</Link>
            </li>

            <li
              className={this.state.navigate === "team" ? "active" : ""}
              onClick={() => this.handleNavigate("team")}
            >
              <Link to="/team">Our Team</Link>
            </li>
            <li
              className={this.state.navigate === "apps" ? "active" : ""}
              onClick={() => this.handleNavigate("apps")}
            >
              <Link to="/apps">Apps</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
