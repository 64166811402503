import React from "react";
import moment from "moment";

export default class FooterComponent extends React.Component {
  render() {
    return (
      <div style={styles.footer}>
        <div>
          <div>
            &#169; {moment().format("YYYY")} www.sabarishnarain.com. All Rights
            Reserved.
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  footer: {
    backgroundColor: "#eee",
    borderTop: "1px solid #d8d8d8",
    padding: "30px 0",
    textAlign: "center",
    fontSize: "0.8em",
  },
};
