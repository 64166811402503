/* eslint-disable react/prop-types */
import { createContext, useContext } from "react";
import React from "react";

export const LayoutContext = createContext({});

export const useLayoutContext = () => useContext(LayoutContext);

export default function LayoutProvider({ children }) {
  const userAgent = window.navigator.userAgent;
  const isMobile =
    userAgent !== undefined &&
    (userAgent.includes("Android") || userAgent.includes("iPhone"));

  return (
    <LayoutContext.Provider value={{ isMobile }}>
      {children}
    </LayoutContext.Provider>
  );
}
