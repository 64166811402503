import React from "react";

export default class FNXLegacyComponent extends React.Component {
  render() {
    return (
      <div id="contents">
        <div>
          <div>
            <h1 style={{ color: "rebeccapurple" }}> FamilyNetworx (Legacy) </h1>
          </div>
          <div>
            Sadly, the legacy application is no longer hosted in the cloud to
            cut our costs towards cloud consumption. You can request access by
            emailing us. And, good news is we are still open to accept
            contributions. If you or your friends are interested to take over
            from us, please do let us know.
          </div>

          <div>
            <h1 style={{ color: "rebeccapurple" }}> Features </h1>
          </div>

          <div>
            <div>
              <ul>
                <li>Threads</li>
                <li>Reminders</li>
                <li>Expenses</li>
                <li>Password Vault & Secured notes</li>
              </ul>
            </div>

            <div>
              <h1 style={{ color: "rebeccapurple" }}> Quick Look </h1>
            </div>

            <div>
              <div>
                <h3> Welcome Page </h3>
                <img src="../fnx-legacy-welcome.png" style={{ width: "80%" }} />
              </div>

              <div>
                <h3> Home Page </h3> Displays the dashboard of number of
                discussion threads assigned to you and active reminders.
                <img src="../fnx-legacy-home.png" style={{ width: "80%" }} />
              </div>

              <div>
                <h3> Expenses </h3> Displays the total expenses and list for
                current month. You can go back upto 36 months of expenses and
                compare them upto 2 years. active reminders.
                <img
                  src="../fnx-legacy-expenses.png"
                  style={{ width: "80%" }}
                />
              </div>

              <div>
                <h3> Expenses </h3> Create a discussion thread and assign to a
                member. Discussion threads can be tasks, or chores that you want
                to track yourself or onto others within your family.
                <img
                  src="../fnx-legacy-create-thread.png"
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
