import React from "react";

export class LogoComponent extends React.Component {
  render() {
    return (
      <div className="logo">
        <div style={{ lineHeight: "30px" }}>
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "none",
              color: "white",
            }}
            id="logo-title"
            href="/"
          >
            {"sabarish narain"}
          </a>
        </div>
        <div style={{ maxWidth: "150px", display: "flex", marginTop: "0px" }}>
          <div style={{ border: "2px solid red", width: "30%" }}></div>
          <div
            style={{
              border: "2px solid white",
              width: "70%",
              borderTopRightRadius: "3px",
              borderBottomRightRadius: "3px",
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export class LogoComponentXXL extends React.Component {
  render() {
    return (
      <div className="logo">
        <div style={{ lineHeight: "30px" }}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "xx-large",
              animation: "fadein 5s",
            }}
            id="logo-title"
          >
            {"sabarishnarain.com"}
          </span>
        </div>
        <div style={{ maxWidth: "300px", display: "flex", marginTop: "3px" }}>
          <div
            style={{
              border: "2px solid red",
              width: "30%",
              borderRadius: "5px",
            }}
          ></div>
          <div
            style={{
              border: "2px solid #e2e2e2",
              width: "70%",
              borderRadius: "5px",
            }}
          ></div>
        </div>
      </div>
    );
  }
}
