import React from "react";
import "./ExpensehutWidgetComponent.css";
export default class ExpenseHutWidgetComponent extends React.Component {
  render() {
    return (
      <div className="exhut-widget">
        <div className="exhut-widget-title">
          <div>
            <a
              href="https://www.expensehut.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="expense-hut-logo.png"
                className="exhut-widget-title-img"
              ></img>
            </a>
          </div>
          <div className="exhut-widget-title-right-panel">
            <div className="exhut-widget-title-right-panel-title">
              <span className="text-xxl">Expense Hut </span>
            </div>
            <div>
              <span className="text-smaller">
                {" "}
                Our free cloud based expense tracker.{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="exhut-widget-mobile-logo-panel">
          <img src="google-play-badge.png" width="120px" />
          <img src="apple-store-badge.svg" width="100px" />
        </div>

        <div className="exhut-widget-features-panel">
          <div className="exhut-widget-features-panel-title text-xl">
            Features
          </div>
          <div style={{ marginLeft: 20 }}>
            <ul>
              <li>
                Connect to bank account to automatically add transactions.
              </li>
              <li>
                SMS BAL to +1 (855) 934-1064 to see balance on all credit cards.
              </li>
              <li>Scan receipts using our mobile app.</li>
              <li>Live currency conversion for savings.</li>
              <li>Analytical views and charts.</li>
              <li>Available in Android and iOS.</li>
            </ul>
          </div>
        </div>

        <div className="exhut-widget-features-panel">
          <div className="exhut-widget-features-panel-title text-xl">
            What&apos;s New
          </div>
          <div style={{ marginLeft: 20 }}>
            <ul>
              <li>Google Signin (Jan 17th 2022)</li>
              <li>Scan receipts in Mobile.</li>
              <li>Live currency conversion rates.</li>
              <li>Loans tracker</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
