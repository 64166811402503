import React from "react";
import "./ProfileComponent.css";

export default class ProfileSabarishComponent extends React.Component {
  render() {
    return (
      <div id="contents">
        <div style={{ display: "flex" }}>
          <div
            style={{ width: "40%", display: "flex", justifyContent: "center" }}
          >
            <img src="sabarishnarain.jpg" width="60%" height="350px" />
          </div>

          <div style={{ width: "60%" }}>
            <div>
              <div style={{ alignItems: "center", marginBottom: "20px" }}>
                <h3> About Sabarish</h3>
                <h5>
                  {" "}
                  Software Engineer & Co-Founder -{" "}
                  <a
                    href="https://www.expensehut.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ExpenseHut
                  </a>{" "}
                </h5>
                <div>
                  <span style={{ margin: "0px 10px 0px 0px", fontSize: 15 }}>
                    sabarish.narain@gmail.com
                  </span>
                  |
                  <span style={{ margin: "0px 10px", fontSize: 15 }}>
                    <a
                      href="./sabarish_resume.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Resume
                    </a>
                    .
                  </span>
                  |
                  <a
                    href="https://www.linkedin.com/in/sabarishnarain"
                    style={{ margin: "0px 10px", fontSize: 15 }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </div>
              </div>

              <div className="profile-body">
                <p>
                  I am a software engineer, husband, a father and a common man
                  who wants to to take a step forward to work on things to make
                  this world a better place.
                </p>
                <p>
                  Over my career I have worked on multiple areas - web, backend
                  and mobile. My expertise is towards modern web frameworks,
                  backend services and mobile applications. My ultimate goal is
                  to extend my learning curve and express commitment in work I
                  do.
                </p>

                <p>
                  My experience with specific tools are listed below. For more
                  details, please download my
                  <a
                    href="./sabarish_resume.pdf"
                    target="_blank"
                    rel="noreferrer"
                    style={{ margin: "0px 5px" }}
                  >
                    resume
                  </a>
                  .
                </p>

                <p>
                  For more of my work towards personal projects that are
                  commerically hosted today, please see{" "}
                  <a href="./index.html"> here </a>. Outside my professional
                  interests, I am a father, husband and an avid motorcyclist.
                </p>
              </div>
            </div>

            <div>
              <h3> My Experience </h3>
            </div>

            <div>
              <table className="profile-table">
                <tbody>
                  <tr>
                    <td>Front End Web frameworks</td>

                    <td> React, LitElement </td>
                  </tr>

                  <tr>
                    <td>Back end</td>
                    <td>Java, Spring, NodeJs</td>
                  </tr>

                  <tr>
                    <td>Mobile development</td>

                    <td> Android, ReactNative </td>
                  </tr>

                  <tr>
                    <td>Containerization</td>

                    <td> Docker, Kubernetes </td>
                  </tr>

                  <tr>
                    <td>Cloud</td>

                    <td> Azure, AWS </td>
                  </tr>

                  <tr>
                    <td>Database</td>

                    <td> Oracle, Postgres, MongoDB </td>
                  </tr>

                  <tr>
                    <td>Tests - Web</td>

                    <td>junit, mocha, jest, cypress, selenium</td>
                  </tr>

                  <tr>
                    <td>Tests - Mobile</td>

                    <td>appium, espresso</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <h3> Popular projects </h3>
            </div>

            <div>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  <a
                    target="_blank"
                    href="https://www.expensehut.com"
                    rel="noreferrer"
                  >
                    {" "}
                    ExpenseHut{" "}
                  </a>{" "}
                  Free finance management platform for everyone.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
