import React from "react";
import { Link } from "react-router-dom";
import ExpenseHutWidgetComponent from "./ExpenseHutWidgetComponent";
import "./WelcomeComponent.css";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import WelcomeSliderComponent from "./WelcomeSliderComponent";
import { useLayoutContext } from "../../providers/layout.provider";

export default function WelcomeComponent() {
  const { isMobile } = useLayoutContext();

  return (
    <div id="contents" style={{ display: "flex" }}>
      <div
        style={{
          padding: "10px 20px",
          fontFamily: "Source Sans Pro, sans-serif",
        }}
      >
        <div className={isMobile ? "divColumn line-30" : "divSpread"}>
          <img
            src="sabarishnarain.jpg"
            style={{ width: 100, height: 100, borderRadius: 50 }}
          />

          <div>
            <p
              className={isMobile ? "my-20" : "mx-20"}
              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              I am a software engineer, husband, father and a common man who
              wants to contribute to modern and digital innovations to make this
              world a better place.
            </p>

            <p
              className={isMobile ? "my-20" : "my-10 mx-20"}
              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              ExpenseHut is not just an app - it is a platform that hosts
              multipe software applications that solves real world problems.
            </p>
          </div>
        </div>

        <WelcomeSliderComponent />

        <Divider sx={{ marginBottom: "45px" }} />

        <Typography variant="h5" gutterBottom component="div">
          The Vision
        </Typography>

        <div>
          <span style={{ fontSize: "1.25rem", fontWeight: "" }}>
            To make a difference building free software applications and promote
            self learning.
          </span>
        </div>

        <Divider sx={{ margin: "45px 0px 20px 0px" }} />

        <Typography variant="h5" gutterBottom component="div">
          Who we are?
        </Typography>

        <div>
          <span style={{ fontSize: "1.25rem", fontWeight: "" }}>
            My wife and I started this as an R&D project for everyday usage.
            With continous support from our users, we decided to build
            ExpenseHut as a platform that houses multiple products each
            targetting a different area.
          </span>
        </div>

        <Divider sx={{ margin: "45px 0px 20px 0px" }} />

        <Typography variant="h5" gutterBottom component="div">
          What do we have?
        </Typography>

        <div>
          <span style={{ fontSize: "1.25rem", fontWeight: "" }}>
            Today, we have 4 different apps (see above) solving some of the
            biggest challenges we face everyday. With a ExpenseHut's identity
            mechanism, you can access all our apps under the same credentials.
          </span>
        </div>

        <Divider sx={{ margin: "45px 0px 20px 0px" }} />

        <Typography variant="h5" gutterBottom component="div">
          What we are <strong>not?</strong>
        </Typography>

        <div>
          <span style={{ fontSize: "1.25rem", fontWeight: "" }}>
            We are <b>NOT</b> a business or a for-profit. All apps under
            ExpenseHut are self-funded.
          </span>
        </div>

        <Divider sx={{ margin: "45px 0px 20px 0px" }} />

        <Typography variant="h5" gutterBottom component="div">
          Have more questions?
        </Typography>

        <Typography variant="body1" gutterBottom>
          Please visit <Link to="/aboutus">here</Link> or{" "}
          <a href="mailto:expensehut@gmail.com">email</a>.
        </Typography>
      </div>

      <div
        style={{
          minWidth: "20%",
          background:
            "linear-gradient(90deg, rgba(0,107,136,1) 25%, rgba(38,0,51,1) 100%)",
          padding: "20px",
          borderRadius: "10px",
          display: window.innerWidth < 800 ? "none" : "block",
        }}
      >
        <ExpenseHutWidgetComponent />
      </div>
    </div>
  );
}
