import "./App.css";
import React from "react";
import HeaderComponent from "./components/HeaderComponent";
import WelcomeComponent from "./components/welcome/WelcomeComponent";
import AboutUsComponent from "./components/aboutus/AboutUsComponent";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import OurTeamComponent from "./components/team/OurTeamComponent";
import OurAppsComponent from "./components/ourapps/OurAppsComponent";
import ProfileSabarishComponent from "./components/profile/ProfileSabarishComponent";
import ProfilePriyaComponent from "./components/profile/ProfilePriyaComponent";
import FNXLegacyComponent from "./components/applications/FNXLegacyComponent";
import FooterComponent from "./components/footer/FooterComponent";
import LayoutProvider from "./providers/layout.provider";

function App() {
  return (
    <LayoutProvider>
      <Router>
        <HeaderComponent />

        <Switch>
          <Route path="/me">
            <ProfileSabarishComponent />
          </Route>
          <Route path="/priya*">
            <ProfilePriyaComponent />
          </Route>
          <Route path="/apps/fnxlegacy">
            <FNXLegacyComponent />
          </Route>
          <Route path="/aboutus">
            <AboutUsComponent />
          </Route>
          <Route path="/team">
            <OurTeamComponent />
          </Route>
          <Route path="/apps">
            <OurAppsComponent />
          </Route>
          <Route path="/">
            <WelcomeComponent />
          </Route>
        </Switch>

        <FooterComponent />
      </Router>
    </LayoutProvider>
  );
}

export default App;
