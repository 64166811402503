import React from "react";

export default class AboutUsComponent extends React.Component {
  render() {
    return (
      <div id="contents">
        <div>
          <iframe
            style={{ width: "100%", minHeight: "800px", border: "0px" }}
            src="https://www.blogs.expensehut.com/articles/6249da883ca033188a7e41a4/published/body"
          />
        </div>
      </div>
    );
  }
}
